@import url("https://fonts.googleapis.com/css?family=Fugaz+One");
@import url("https://fonts.googleapis.com/css?family=Charm|Fugaz+One|Tangerine");
/* latin-ext */

@font-face {
  font-family: "B820-Script";
  src: url("../../assets/fonts/B820-Script.ttf") format("truetype");
}

@font-face {
  font-family: "Damfplatz Solid Black";
  src: url("../../assets/fonts/Dampfplatz\ Solid\ Black.ttf") format("truetype");
}

@font-face {
  font-family: "E780-Blackletter-Regular";
  src: url("../../assets/fonts/E780-Blackletter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "IncisedBlackWide Normal";
  src: url("../../assets/fonts/IncisedBlackWide\ Normal.ttf") format("truetype");
}

@font-face {
  font-family: "Ottoman";
  src: url("../../assets/fonts/Ottoman.ttf") format("truetype");
}

@font-face {
  font-family: "Minster No 1";
  src: url("../../assets/fonts/Minster\ No\ 1.ttf") format("truetype");
}

@font-face {
  font-family: "Agnes-Regular";
  src: url("../../assets/fonts/Agnes-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Agnes-Bold";
  src: url("../../assets/fonts/Agnes-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Asenine";
  src: url("../../assets/fonts/Asenine.ttf") format("truetype");
}

@font-face {
  font-family: "Subpear";
  src: url("../../assets/fonts/Subpear.ttf") format("truetype");
}

@font-face {
  font-family: "Tuffy Regular";
  src: url("../../assets/fonts/Tuffy\ Regular.ttf") format("truetype");
}

body {
  background-color: lightgray;
}

#buttoncont {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

#download {
  color: white;
  padding: 8px 20px;
  font-size: 20px;
  background: #750000;
  border: none;
  font-weight: 700;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
}

#download:hover {
  color: black;
  background: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  transform: translateY(-3px);
}

#pdfcont {
  border-radius: 5px;
  margin: 20px auto;
  height: 555px;
  width: 660px;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 1px 30px black;
}

#pdfexport {
  width: 100%;
  height: 100%;
}

#incont {
  display: block;
  border: 2px solid white;
  padding: 20px;
}

#incont2 {
  background-image: url("../../assets/images/ringu.png");
  background-size: cover;

  display: block;
  border: 1.3px solid #757575;
}

#title {
  text-align: center;
  font-size: 35px;
  margin-bottom: 5px;
  letter-spacing: 2px;

  font-family: "Ottoman";
}

#courseimg {
  width: 70px;
  display: block;
  margin: 10px auto;
}

#name {
  font-size: 30px;
  font-family: "Agnes-Bold";
  text-align: center;
}

#p1 {
  font-family: "Agnes-Bold";

  text-align: center;
}

#p2 {
  font-family: "Agnes-Bold";
  text-align: center;
}

#p3 {
  font-family: "Agnes-Bold";
  text-align: center;
}

#coursetitle {
  margin-top: 0px;
  font-size: 30px;
  text-align: center;

  font-family: "Ottoman";
}

#bottomrow {
  height: 100px;
  display: grid;

  grid-template-columns: repeat(3, 1fr);
}
#bottomleftimg {
  width: 120px;
  display: block;
  margin: 10px;
}

#sigcont {
  margin-left: 40px;
}

#sigcont p {
  margin: 0;
  font-size: 9px;
  text-align: left;
}
#bottomrightimg {
  height: 60px;
  width: 100px;
  display: block;
}

#date {
  margin-top: 40px;
  margin-right: 40px;
  font-size: 9px;
}
